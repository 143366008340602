import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import StickyMenu from "@containers/sticky-menu";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaOne from "@components/introarea/layout-4";
import BoxSection from "@components/BoxSection/layout-one/layout-3";
import LanguageTranslators from "@containers/language-translation/translators";
import KeyBenifits from '@containers/ai-voiceover/key-benifits';
import LocalizeGrid from "@components/localize-grid/layout-4";
import BoxSectionOne from "@components/BoxSection/layout-three/layout-2";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import UseScroll from '@containers/scroll';
import QuoteForm from "@containers/translation-quote-form";
import ContactArea from "@containers/contact-us";
import FeatureGrid from "@components/feature-grid";
const Footer =lazy(()=>import("@layout/footer/layout-01"))

export const query = graphql`
  query InclusiveWritingSolutionPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "inclusive-writing-solution" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;
const InclusiveWritingSolutionPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page?.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 100) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Inclusive Writing Solutions"
        description=""
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      {
        !isMobile &&  <StickyMenu data={content["sticky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["inclusive-page-header-section"]} />
        <QuoteForm />
        {
           (!isMobile || (isMobile && showRestComponents)) && <>
           <FeatureGrid data={content["inclusive-features"]} />
           <IntroAreaOne layout={3} data={content["inclusive-intro-body"]} />
           </>
        }
        {
          showRestComponents && <>
            <BoxSection layout={7} data={content["inclusive-use-cases-body"]} />
            <LanguageTranslators data={content["tailored-inclusive-writing-form-body"]} />
            <KeyBenifits layout={1} data={content['multilingual-inclusive-writing']} />
            <LocalizeGrid layout={1} data={content["boost-reputation-reach-compliance-inclusively"]} />
            <IntroAreaOne layout={2} data={content["inclusive-ai-voiceover-data"]} />
            <BoxSectionOne
              layout={5}
              data={content["inclusive-key-benefits-data"]}
            />
            <CaseStudy layout={3} data={caseStudiesData} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

InclusiveWritingSolutionPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default InclusiveWritingSolutionPage;
